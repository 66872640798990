import React from 'react';
import Layout from '~components/layouts/Default';
import Container from '~components/common/Container';
import { StaticImage } from 'gatsby-plugin-image';
import GridTwo from '~components/common/GridTwo';
import GridThree from '~components/common/GridThree';
import LogoCloud from '~components/common/LogoCloud';
import H2 from '~components/typography/H2';
import H3 from '~components/typography/H3';
import P from '~components/typography/P';
import Divider from '~components/common/Divider';
import Feature from '~components/common/Feature';
import { LightBulbIcon, CalendarIcon, CheckCircleIcon, BookOpenIcon, ChatAlt2Icon, ChatIcon, ArrowDownIcon } from '@heroicons/react/outline';
import backgroundHeader from '../media/landingpages/gruppe/header.jpg';
import backgroundSection from '../media/landingpages/gruppe/image_02.jpg';
import AddFacebook from '~components/newsletter/AddFacebook';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Regelmäßige Impulse für Entspannung und Achtsamkeit" desc="Werde Teil unserer Entspannungshelden Community und lerne Entspannungsübungen kennen">
      <div className="relative bg-cover bg-left-top bg-no-repeat md:py-8" style={{ backgroundImage: "url('" + backgroundHeader + "')" }}>
        <div className="md:mx-auto md:max-w-screen-xl md:px-4 md:py-32">
          <GridTwo>
            <div className="z-10 space-y-4 bg-white p-6 md:max-w-lg">
              <div className="space-y-6 text-center md:text-left">
                <H2>Exklusive Inhalte für dein Stressmanagement</H2>
                <P>
                  Hey, ich bin Johannes und zeige dir, wie du dich vom Alltagsstress befreien und entspannen kannst. In unserer exklusiven Gruppe zeige ich dir mehr über unsere Methoden und unseren Kurs. Der Beitritt in die Gruppe ist
                  kostenfrei!
                </P>
              </div>
              <AddFacebook />
            </div>
          </GridTwo>
        </div>
      </div>
      <LogoCloud />
      <Container>
        <GridTwo itemsCenter>
          <div>
            <H2 className="mb-5">Das passiert nach deiner Anmeldung</H2>
            <P className="mb-5">
              Du erhältst eine Mail von uns mit der Einladung zur exklusiven Facebook Gruppe. Sobald du dieser beigetreten bist, heißen wir dich herzlich willkommen. Du kannst nun einen einmaligen Einblick in unser Programm erhalten und uns
              persönlich Fragen stellen!
            </P>
            <Divider className="my-8" />
            <Feature svg={<LightBulbIcon className="h-8 w-8 text-brand" />} title="Frage uns was du schon immer zum Thema Entspannung und Stressmanagement wissen wolltest." />
          </div>
          <div>
            <StaticImage src="../media/landingpages/gruppe/image_01.jpg" alt="Gruppe Image 1" className="" />
          </div>
        </GridTwo>
      </Container>
      <Container>
        <div className="mb-12 text-center">
          <H3>
            <span className="text-sm uppercase text-brand">PROFESSIONELL UND EINFACH</span>
          </H3>
          <H2>Warum Entspannungshelden?</H2>
        </div>
        <GridThree itemsCenter>
          <div>
            <div className="mx-auto max-w-md p-6 text-center">
              <CalendarIcon className="mx-auto mb-5 h-16 w-16 text-brand" />
              <H3 className="mb-3">Zeit und Ortsunabhängig</H3>
              <P>Greife jederzeit auf unsere Kurse zu, egal wo und egal wann.</P>
            </div>
          </div>
          <div>
            <div className="mx-auto max-w-md bg-white p-6 text-center shadow-lg">
              <CalendarIcon className="mx-auto mb-5 h-16 w-16 text-brand" />
              <H3 className="mb-3">Offiziell zertifiziert</H3>
              <P>Unsere Kurse sind durch die Zentrale Prüfstelle Prävention zertifiziert - Qualität ist dir garantiert!</P>
            </div>
          </div>
          <div>
            <div className="mx-auto max-w-md p-6 text-center">
              <CalendarIcon className="mx-auto mb-5 h-16 w-16 text-brand" />
              <H3 className="mb-3">Kostenübernahme</H3>
              <P>Alle unserer Kurse werden mit ca. 75€ - 150€ von deiner Krankenkasse bezuschusst.</P>
            </div>
          </div>
        </GridThree>
      </Container>
      <div className="relative bg-cover bg-no-repeat py-8" style={{ backgroundImage: "url('" + backgroundSection + "')" }}>
        <Container>
          <GridTwo itemsCenter>
            <div className="z-10 max-w-lg bg-white p-6">
              <H2 className="mb-5">Was erwartet dich in der Gruppe?</H2>
              <div className="space-y-6">
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="3 mal die Woche neuer Imput zu den Themen Achtsamkeit, Entspannung und Stressmanagement." />
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Poste deine Fragen in die Gruppe. Die Entspannungshelden antworten dir persönlich!" />
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="2 Mal die Woche findet ein Live Event mit exklusiven Infos statt" />
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Alle Infos und nie dagewesene Einblicke in unsere Kurse" />
                <Divider className="my-8" />
                <Feature svg={<LightBulbIcon className="h-8 w-8 text-brand" />} title="Reguliere deinen Alltagsstress mit unseren hilfreichen Trainings!" />
              </div>
            </div>
          </GridTwo>
        </Container>
      </div>

      <Container>
        <div className="mb-12 text-center">
          <H3>
            <span className="text-sm uppercase text-brand">GRUPPENBEITRITT</span>
          </H3>
          <H2>Das bekommst du in der Gruppe</H2>
        </div>
        <GridThree itemsCenter>
          <div>
            <div className="mx-auto max-w-md p-6 text-center">
              <BookOpenIcon className="mx-auto mb-5 h-16 w-16 text-brand" />
              <H3>Wissensvermittlung</H3>
            </div>
          </div>
          <div>
            <div className="mx-auto max-w-md p-6 text-center ">
              <ChatAlt2Icon className="mx-auto mb-5 h-16 w-16 text-brand" />
              <H3>Gruppendiskussionen</H3>
            </div>
          </div>
          <div>
            <div className="mx-auto max-w-md p-6 text-center">
              <ChatIcon className="mx-auto mb-5 h-16 w-16 text-brand" />
              <H3>Experten im Live Gespräch</H3>
            </div>
          </div>
        </GridThree>
        <a className="mt-16 block text-center" href="#more">
          <P>
            <span className="mb-3 text-lg">Erfahre mehr dazu</span>
          </P>
          <ArrowDownIcon className="mx-auto mb-5 h-8 w-8 text-brand" />
        </a>
      </Container>
      <div className="overflow-hidden bg-gray-50 py-8 md:py-16">
        <Container className="relative" dotsRight>
          <GridTwo itemsCenter>
            <div id="more">
              <H2 className="mb-5">Wissensvermittlung</H2>
              <P className="mb-5">
                Stress im Alltag? Schwierigkeiten dabei einfach mal abzuschalten? Wir zeigen dir die besten Techniken und das nötige Wissen für deine Entspannung. Lerne von zertifizierten Trainern, wie du mehr Entspannung in deinen
                Alltagsstress bringen kannst. Die Anmeldung zu unserer Gruppe ist kostenfrei und gibt dir exklusive Einblicke zu unserem Programm. Also... einmal tief durchatmen und anmelden!
              </P>
              <div className="space-y-6">
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Zertifizierte Trainer" />
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Kostenlose Wissensvermittlung" />
                <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Einblicke in unseren Kurs" />
              </div>
            </div>
            <div>
              <StaticImage src="../media/landingpages/gruppe/image_03.jpg" alt="Gruppe Image 3" className="" />
            </div>
          </GridTwo>
        </Container>

        <div className="overflow-hidden">
          <Container className="relative" dotsLeft>
            <GridTwo itemsCenter>
              <div>
                <StaticImage src="../media/landingpages/gruppe/image_04.jpg" alt="Gruppe Image 4" className="" />
              </div>
              <div>
                <H2 className="mb-5">Trete der Diskussion bei</H2>
                <P className="mb-5">
                  In unserer privaten Facebook Gruppe bleiben keine Fragen offen. Du kannst jederzeit Fragen zum Kurs, unseren Trainings oder zu den Themen Entspannung, Achtsamkeit und Stressbewältigung in die Gruppe posten. Unsere Trainer
                  und Experten antworten dir persönlich und helfen dir! Gerne kannst du auch Themen mit anderen Gruppenmitgliedern diskutieren und dich austauschen!
                </P>
                <div className="space-y-6">
                  <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Gruppendiskussionen" />
                  <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Unsere Experten antworten persönlich" />
                  <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Fragen sind jederzeit willkommen" />
                </div>
              </div>
            </GridTwo>
          </Container>
        </div>
        <div className="overflow-hidden">
          <Container className="relative" dotsRight>
            <GridTwo itemsCenter>
              <div>
                <H2 className="mb-5">Experten im Live Gespräch</H2>
                <P className="mb-5">
                  Zusätzlich zu unseren Gruppendiskussionen und den Einblicke zu unseren Kursen kannst du unsere Coachings auch Live auf Facebook miterleben. Unsere Trainer gehen mehrmals die Woche live um unsere Gruppenmitglieder mit
                  weiteren spannenden Infos zur Hilfe zu eilen. Hier kannst du deine Fragen live an unsere Experten stellen!
                </P>
                <div className="space-y-6">
                  <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Live Interaktion auf Facebook" />
                  <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Fragen von Angesicht zu Angesicht" />
                  <Feature svg={<CheckCircleIcon className="h-8 w-8 text-brand" />} title="Exklusive Inhalte direkt auf deinem Handy verfügbar" />
                </div>
              </div>
              <div>
                <StaticImage src="../media/landingpages/gruppe/image_05.jpg" alt="Gruppe Image 5" className="" />
              </div>
            </GridTwo>
          </Container>
        </div>

        <Container>
          <GridTwo itemsStart>
            <div className="bg-red-500 p-6 text-white md:p-12">
              <H3 className="mb-5">
                <span className="text-sm uppercase">BISHERIGE SITUATION</span>
              </H3>
              <H2 className="mb-5">Stress im Alltag</H2>
              <P>
                <span className="text-white">Kennst du das? Die Arbeit wächst dir über den Kopf, du hast Schwierigkeiten, dich zu entspannen und aufs Wesentliche zu konzentrieren?</span>
              </P>
            </div>
            <div className="bg-green-500 p-6 text-white md:p-12">
              <H3 className="mb-5">
                <span className="text-sm uppercase">ZIELSITUATION</span>
              </H3>
              <H2 className="mb-5">Entspannt und befreit</H2>
              <P>
                <span className="text-white">Wir möchten, dass du gestärkt aus unserem Training hervorgehst und mehr darüber weißt, wie du entspannter durch den Tag gehen kannst.</span>
              </P>
            </div>
          </GridTwo>
        </Container>
      </div>
      <Container>
        <div className="mb-6 text-center">
          <H2>Trete hier der exklusiven Gruppe bei</H2>
        </div>
        <div className="mx-auto max-w-lg">
          <AddFacebook />
        </div>
        <div className="relative overflow-hidden">
          <StaticImage src="../media/landingpages/gruppe/image_johannes_liegend.png" alt="Gruppe Image 6" className="mx-auto block max-w-lg md:translate-x-32 md:transform" placeholder="blurred" />
        </div>
      </Container>
    </Layout>
  );
}
