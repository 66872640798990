import React from 'react';
import P from '../typography/P';

const Feature = ({ svg, title, text }) => {
  return (
    <div className="relative">
      <dt className="flex flex-row">
        {svg}
        <P className="ml-3 flex-1">
          <span className="text-lg">{title}</span>
        </P>
      </dt>
      {text && (
        <dd className="mt-2 ">
          <P>{text}</P>
        </dd>
      )}
    </div>
  );
};

export default Feature;
